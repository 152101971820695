

$('document').ready(function () {
	$('.js-tile').responsiveEqualHeightGrid();
	$('.js-kit__description').responsiveEqualHeightGrid();

	$('.js-scroll-to').click(function(e){
		var id = $(this).attr("href")
		e.preventDefault();
		$('html, body').animate({
		    scrollTop: $(id).offset().top
		}, 500);
	});
});


